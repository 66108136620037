import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      description: { required },
      price: { required },
      quantity: { required }
    },
  },
  data() {
    return {
      drawerOpened: false,
      form: {
        description: null,
        price: null,
        quantity: null,
        discount: null
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
      }
    }
  },
  methods: {
    resetForm () {
      this.form.description = null
      this.form.price = null
      this.form.quantity = null
      this.form.discount = null
      
      this.$nextTick(() => {
        this.$v.$reset();
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: false
      })
    },
    async onSubmitForm() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.$emit('close-event', { 
        status: true,
        data: this.form
      })
      this.drawerOpened = false
      this.resetForm()
    }
  }
}
